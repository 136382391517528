import { Column } from '@dfds-ui/react-components'
import { useTranslation } from 'react-i18next'
import { FormField } from './FormField'
import { string } from 'yup'

export const FirstLastNameFields = ({ required = true }) => {
  const { t } = useTranslation()
  const stringValidation = string()
  const requiredFields = required
    ? {
        validation: stringValidation,
        required,
      }
    : {}

  return (
    <>
      <Column m={6}>
        <FormField
          name="firstName"
          label={t('FORM-FIRST-NAME')}
          type="text"
          {...requiredFields}
        />
      </Column>
      <Column m={6}>
        <FormField
          name="lastName"
          label={t('FORM-LAST-NAME')}
          type="text"
          {...requiredFields}
        />
      </Column>
    </>
  )
}
