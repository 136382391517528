import { useInitField } from './FormContext'
import { Field } from 'formik'
import { AddressSelect } from '@dfds-ui/google-places'
import { Label } from '@dfds-ui/react-components'
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'

export const AddressSelectField = ({
  name,
  initialValue,
  validation,
  required = false,
  label,
  validationMessage,
  ...rest
}) => {
  useInitField({ name, validation, initialValue, required })
  const { t } = useTranslation()
  return (
    <Field name={name}>
      {({
        field,
        form: { touched, setFieldTouched, setFieldValue, values },
      }) => {
        const showValidationMesaage =
          !!touched[name] && required && !values[name]

        return (
          <div
            css={css`
              margin-bottom: ${showValidationMesaage ? '5px' : '20px'};
            `}
          >
            {label && <Label required={required}>{label}</Label>}
            <AddressSelect
              {...field}
              types={['(cities)']}
              placeholder={t('FORM-CITY, COUNTRY')}
              isClearable
              {...rest}
              onBlur={() => setFieldTouched(name, true)}
              onChange={(value) => {
                setFieldTouched(name, true)
                setFieldValue(name, value)
              }}
              error={showValidationMesaage && validationMessage}
            />
          </div>
        )
      }}
    </Field>
  )
}
