import React from 'react'

export const useFormSubmit = () => {
  const [error, setError] = React.useState(false)
  const [isSubmitted, setSubmitted] = React.useState(false)
  const [reCaptchaRef, setReCaptchaRef] = React.useState()
  const [reCaptchaVal, setReCaptchaVal] = React.useState()
  const [isReCaptchaInvisible, setIsReCaptchaVisible] = React.useState(false)
  const submitForm = async ({
    languageId,
    templateId,
    formType,
    formData,
    userOnly,
  }) => {
    if (reCaptchaRef && isReCaptchaInvisible) reCaptchaRef.reset()
    try {
      setError(false)
      setSubmitted(false)
      await (
        await fetch(`${process.env.GATSBY_BFF_URL_NO_VER}formSubmit`, {
          method: 'POST',
          body: JSON.stringify({
            languageId,
            templateId,
            formData,
            formType,
            userOnly,
          }),
          headers: {
            'Content-Type': `application/json`,
            Accept: `application/json`,
          },
        })
      ).json()

      setSubmitted(true)
      if (reCaptchaRef && isReCaptchaInvisible) reCaptchaRef.execute()
    } catch (e) {
      setError(e)
    }
  }
  return {
    submitForm,
    error,
    isSubmitted,
    setReCaptchaRef,
    setIsReCaptchaVisible,
    reCaptchaVal,
    setReCaptchaVal,
  }
}

export const useCRMSubmit = () => {
  const [error, setError] = React.useState(false)
  const [isSubmitted, setSubmitted] = React.useState(false)
  const [reCaptchaRef, setReCaptchaRef] = React.useState()
  const [reCaptchaVal, setReCaptchaVal] = React.useState()
  const [isReCaptchaInvisible, setIsReCaptchaVisible] = React.useState(false)
  const submitForm = async (data, submitUserEmailForm) => {
    if (reCaptchaRef && isReCaptchaInvisible) reCaptchaRef.reset()
    try {
      setError(false)
      setSubmitted(false)
      await (
        await fetch(`${process.env.GATSBY_BFF_URL_NO_VER}formCRMSubmit`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': `application/json`,
            Accept: `application/json`,
          },
        })
      ).json()

      await submitUserEmailForm({
        ...data,
        formData: { ...data },
        userOnly: !error,
      })

      setSubmitted(true)
      if (reCaptchaRef && isReCaptchaInvisible) reCaptchaRef.execute()
    } catch (e) {
      setError(e)
    }
  }
  return {
    submitForm,
    error,
    isSubmitted,
    setReCaptchaRef,
    setIsReCaptchaVisible,
    reCaptchaVal,
    setReCaptchaVal,
  }
}
