import React from 'react'
import { TextField } from '@dfds-ui/react-components'
import { Field } from 'formik'
import { useInitField } from './FormContext'
import { useTranslation } from 'react-i18next'

export const FormField = ({
  name,
  initialValue,
  validation,
  children,
  required,
  hintText,
  hideErrorMessage,
  setFieldErrorStatus,
  ...rest
}) => {
  useInitField({ name, validation, initialValue, required })

  const { t } = useTranslation()
  return (
    <Field name={name}>
      {({
        field,
        form: { errors, touched, setFieldTouched, setFieldValue, values },
      }) => {
        const isFieldInvalid = !!touched[name] && !!errors[name]

        if (setFieldErrorStatus) {
          isFieldInvalid
            ? setFieldErrorStatus(errors[name])
            : setFieldErrorStatus(null)
        }

        if (children) {
          return React.cloneElement(children, {
            error: isFieldInvalid,
            errorMessage: isFieldInvalid && !hideErrorMessage && errors[name],
            name,
            value: values[name],
            onChange: (value) => {
              setFieldTouched(name, true)
              setFieldValue(name, value)
            },
            required,
            ...field,
            ...rest,
          })
        }

        return (
          <TextField
            {...field}
            {...rest}
            hintText={hintText}
            required={required}
            onBlur={() => setFieldTouched(name, true)}
            error={isFieldInvalid}
            errorMessage={
              isFieldInvalid && !hideErrorMessage && t('THE-FIELD-IS-REQUIRED')
            }
          />
        )
      }}
    </Field>
  )
}
