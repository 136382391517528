import { array } from 'yup'
import { Markdown } from '../Markdown'
import { CheckBoxField } from './CheckBoxField'

export const GdprFormCheckbox = ({ label }) => {
  return (
    <CheckBoxField
      type="checkbox"
      name="gdprCheckbox1"
      validation={array().length(1)}
      required
    >
      {<Markdown source={label} />}
    </CheckBoxField>
  )
}
