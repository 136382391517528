import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Label, AssistiveText, Column } from '@dfds-ui/react-components'
import ErrorText from '@dfds-ui/react-components/forms/error-text/ErrorText'

const Container = styled.div`
  display: ${(props) => (props.displayBlock ? 'block' : 'flex')};
  margin-bottom: 4px;
`

const margins = css`
  margin-bottom: 4px;
  display: block;
`

export const RadioGroup = (props) => {
  const {
    name,
    label,
    inverted,
    disabled,
    assistiveText,
    required,
    errorMessage,
    children,
    size,
    value,
    onChange,
    displayBlock,
    columnProps,
    ...rest
  } = props

  let elements = React.Children.toArray(children)

  elements = elements.map((el, i) => {
    return (
      <Column
        key={i}
        m={displayBlock ? 12 : 12 / elements.length}
        {...columnProps}
      >
        {React.cloneElement(el, {
          ...rest,
          name,
          size,
          disabled,
          onChange,
          checked: el.props.value === value,
          error: null, // Not to make radio button circle red, we are disabling error for individual radio element
        })}
      </Column>
    )
  })

  return (
    <div
      css={
        assistiveText || errorMessage
          ? css`
              margin-bottom: 5px;
            `
          : css`
              margin-bottom: 20px;
            `
      }
    >
      <Label size={size} inverted={inverted} required={required} css={margins}>
        {label}
      </Label>
      <Container displayBlock={displayBlock}>{elements}</Container>
      {assistiveText && !errorMessage && (
        <AssistiveText inverted={inverted}>{assistiveText}</AssistiveText>
      )}
      {errorMessage && (
        <ErrorText role="alert" small>
          {errorMessage}
        </ErrorText>
      )}
    </div>
  )
}
