import { useState } from 'react'
import { Column, Label } from '@dfds-ui/react-components'
import { FlexBox } from '../FlexBox'
import styled from '@emotion/styled'
import { FormField } from './FormField'
import ErrorText from '@dfds-ui/react-components/forms/error-text/ErrorText'

const StyledPlusField = styled(FlexBox)`
  width: 10px;
  align-items: center;
  /* margin-bottom: 20px; */
  margin-bottom: ${(props) => (props.errorMessage ? '5px' : '20px')};
`

const StyledCountryCodeField = styled(FormField)`
  width: 50px;
  margin-right: 5px;
  margin-bottom: ${(props) => (props.errorMessage ? '5px' : '20px')};
  /* margin-bottom: 3px; */
`

const StyledPhoneNumberField = styled(FormField)`
  width: calc(100% - 65px);
  min-width: 100px;
  margin-bottom: ${(props) => (props.errorMessage ? '5px' : '20px')};
  /* margin-bottom: 3px; */
`

export const PhoneNumberField = ({
  required,
  columnProps,
  labelProps,
  countryCodeProps,
  numberProps,
}) => {
  const [countryCodeInputValidationError, setCountryCodeInputValidationError] =
    useState()

  const [numberFieldInputValidationError, setNumberFieldInputValidationError] =
    useState()

  const errorMessage =
    countryCodeInputValidationError || numberFieldInputValidationError

  return (
    <Column m={12} {...columnProps}>
      {labelProps && (
        <Label {...labelProps} required={required}>
          {labelProps.text}
        </Label>
      )}
      {countryCodeProps && numberProps && (
        <FlexBox>
          <StyledPlusField errorMessage={errorMessage}>+</StyledPlusField>
          <StyledCountryCodeField
            {...countryCodeProps}
            required={required}
            setFieldErrorStatus={setCountryCodeInputValidationError}
            hideAsterisk
            hideErrorMessage
            errorMessage={errorMessage}
          />
          <StyledPhoneNumberField
            {...numberProps}
            required={required}
            setFieldErrorStatus={setNumberFieldInputValidationError}
            hideAsterisk
            hideErrorMessage
            errorMessage={errorMessage}
          />
        </FlexBox>
      )}
      {errorMessage && (
        <ErrorText role="alert" small>
          {errorMessage}
        </ErrorText>
      )}
    </Column>
  )
}
