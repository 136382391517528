import React from 'react'
import { Formik, Form } from 'formik'
import { object } from 'yup'
import { css } from '@emotion/react'

const FormContext = React.createContext(undefined)

export const useInitField = ({
  name,
  initialValue = '',
  validation = null,
  required = false,
}) => {
  const context = React.useContext(FormContext)

  if (context === undefined) {
    throw new Error('useInitField must be used within a FormContextProvider')
  }

  const { initField, initValidation, requiredFieldError } = context

  React.useEffect(() => {
    initField({ [name]: initialValue })
    if (validation) {
      if (required) {
        /* eslint-disable */
        validation = validation.required(requiredFieldError)
        /* eslint-enable */
      }

      initValidation({
        [name]: validation,
      })
    }
  }, [])
}

export const FormContextProvider = ({
  children,
  onSubmit,
  requiredFieldError,
}) => {
  const [values, setValues] = React.useState({})
  const [validations, setValidations] = React.useState({})

  const initField = (newValue) => {
    setValues((prevValues) => ({ ...prevValues, ...newValue }))
  }
  const initValidation = (newValidation) => {
    setValidations((prevValidations) => ({
      ...prevValidations,
      ...newValidation,
    }))
  }

  if (requiredFieldError === undefined) {
    throw new Error(
      `FormContextProvider is missing 'requiredFieldError' property`,
    )
  }

  return (
    <FormContext.Provider
      value={{
        setValues,
        setValidations,
        initField,
        initValidation,
        requiredFieldError,
      }}
    >
      <Formik
        enableReinitialize
        initialValues={values}
        validationSchema={object().shape(validations)}
        onSubmit={onSubmit}
      >
        {(props) => {
          return (
            <Form
              autoComplete="off"
              css={css`
                width: 100%;
              `}
            >
              {React.cloneElement(children, {
                formProps: props,
              })}
            </Form>
          )
        }}
      </Formik>
    </FormContext.Provider>
  )
}
