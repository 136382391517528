import { Checkbox } from '@dfds-ui/react-components'
import { Field } from 'formik'
import { string } from 'yup'
import { useInitField } from './FormContext'
import { css } from '@emotion/react'
export const CheckBoxField = ({
  name,
  validation,
  initialValue,
  required,
  ...rest
}) => {
  if (rest.required && !validation) {
    validation = string()
  }

  useInitField({ name, validation, initialValue, required })

  return (
    <Field name={name}>
      {({ field: { value: checked, ...field }, form: { errors, touched } }) => {
        const isValidField = !!touched[name] && !!errors[name]

        return (
          <Checkbox
            checked={checked && checked.includes('on')}
            data-focus-visible-added
            {...field}
            {...rest}
            required={required}
            error={isValidField}
            css={css`
              margin-bottom: 10px;
              align-items: flex-start;
            `}
          />
        )
      }}
    </Field>
  )
}
