import { Button } from '@dfds-ui/react-components'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import { useSSR } from 'use-ssr'
import ErrorText from '@dfds-ui/react-components/forms/error-text/ErrorText'

import { useSiteSettingsContext } from '../SiteSettings'

export const FormSubmitButton = ({ handleSubmit, reCaptchaProps, ...rest }) => {
  const { t } = useTranslation()
  const { isSubmitting } = useFormikContext()
  const { googleRecaptchaKey } = useSiteSettingsContext()
  const { isBrowser } = useSSR()

  if (reCaptchaProps && reCaptchaProps.setIsReCaptchaVisible) {
    reCaptchaProps.setIsReCaptchaVisible(reCaptchaProps.showRecaptcha)
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 10,
      }}
    >
      {isBrowser && reCaptchaProps && (
        <>
          <ReCAPTCHA
            sitekey={googleRecaptchaKey}
            size={reCaptchaProps.showRecaptcha ? '' : 'invisible'}
            onChange={
              reCaptchaProps.showRecaptcha && reCaptchaProps.setReCaptchaVal
                ? (val) => reCaptchaProps.setReCaptchaVal(val)
                : (val) => val && handleSubmit
            }
            ref={(el) => {
              reCaptchaProps.setReCaptchaRef(el)
            }}
          />

          {reCaptchaProps.captchaErrorMessage && (
            <ErrorText role="alert">
              {reCaptchaProps.captchaErrorMessage}
            </ErrorText>
          )}
        </>
      )}
      <Button
        type="submit"
        submitting={isSubmitting}
        {...rest}
        style={{ marginTop: 15 }}
      >
        {t('FORM-SEND')}
      </Button>
    </div>
  )
}
